html,
body,
#root,
.contentContainer {
  height: 100%;
  width: 100%;
}

.app {
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
}

.contentContainer {
  padding: 20px;
  overflow-x: auto;
  min-width: 240px;
}
